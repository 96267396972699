import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import {KTIcon} from "@metronic/_metronic/helpers"
import {usePage} from "@inertiajs/react"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import clsx from "clsx"
import _ from "lodash"

const MyWalletDetailsWidget = (props) => {
  const {
    isInvestmentDetails,
    currentList,
    assetsList,
    isSecuritiesListReady
  } = props
  const {props: pageProps} = usePage()

  // Balance
  const [availableBalance, setAvailableBalance] = useState([])
  const [lockedBalance, setLockedBalance] = useState([])
  const [totalBalance, setTotalBalance] = useState([])

  // Investments
  const [tiv, setTiv] = useState([])
  const [pnl, setPnl] = useState(0)
  const [equity, setEquity] = useState([])
  const [pnlPct, setPnlPct] = useState(0)

  useEffect(() => {
    // Balance
    setTotalBalance(pageProps.account_balance.total ? pageProps.account_balance.total.split('.') : ['0', '00'])
    setLockedBalance(pageProps.account_balance.locked ? pageProps.account_balance.locked.split('.') : ['0', '00'])
    setAvailableBalance(pageProps.account_balance.available ? pageProps.account_balance.available.split('.') : ['0', '00'])
  }, [pageProps])

  useEffect(() => {
    if (pageProps.acquired_securities && !_.isEmpty(currentList) && assetsList?.length && isSecuritiesListReady) {
      let tivValue = 0
      tivValue = tivValue + pageProps.acquired_securities.reduce((securityAcc, s) => (
        securityAcc + s.transfers.reduce((transferAcc, t) => {
          const assetPrice = assetsList.find(i => i.label === s?.security?.meta?.payout_asset?.payout_asset_code)?.price || 0

          return transferAcc + Math.abs((t.base_amount || 0) * assetPrice)
        }, 0)
      ), 0)

      const totalValue = pageProps.acquired_securities.reduce((sum, order) => {
        const security = currentList.find(sec => sec.id === order.security_id)
        const assetCode = security?.meta?.payout_asset?.payout_asset_code
        const assetPrice = assetsList.find(i => i.label === assetCode)?.price
        const price = (security?.meta?.current_price?.value || 0) * assetPrice
        const itemValue = Number(price) * Number(order.quantity)

        return sum + itemValue
      }, 0)

      setTiv(tivValue ? String(Number(tivValue).toFixed(2)).split('.') : ['0', '00'])
      setEquity(totalValue ? String(Number(totalValue).toFixed(2)).split('.') : ['0', '00'])
      setPnl(totalValue - tivValue)
      setPnlPct(((totalValue - tivValue) / tivValue) * 100)
    } else {
      setPnl(0)
      setTiv(['0', '00'])
      setEquity(['0', '00'])
    }
  }, [currentList, assetsList])

  const walletDataMapper = [
    {
      name: 'My Wallet',
      icon: 'wallet',
      value: totalBalance,
      iconColor: 'primary',
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-12 col-xl-4 col-xxl-12 pt-5 pt-md-6 pt-xxl-0' :
        'col-12 col-sm-4 col-xl-12 pt-5 pt-xl-0'
    },
    {
      name: 'Available Balance',
      icon: 'chart-simple-2',
      value: availableBalance,
      iconColor: 'success',
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
        'col-12 col-sm-4 col-xl-12 pt-6'
    },
    {
      name: 'Locked Balance',
      icon: 'chart-simple-3',
      value: lockedBalance,
      iconColor: 'danger',
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
        'col-12 col-sm-4 col-xl-12 pt-6'
    },
  ]

  const investmentDataMapper = [
    {
      name: 'Total Investments',
      icon: 'tag',
      value: tiv,
      iconColor: 'primary',
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-12 col-xl-4 col-xxl-12 pt-5 pt-md-6 pt-xxl-0' :
        'col-12 col-sm-4 col-xxl-12 pt-6 pt-xxl-0'
    },
    {
      name: 'Total PNL',
      icon: 'graph-4',
      value: Math.abs(pnl) ? String(Math.abs(pnl).toFixed(2)).split('.') : ['0', '00'],
      iconColor: Number(pnl) <= -0.0001 ? 'danger' : (Number(pnl) >= 0.0001 ? 'success' : 'muted'),
      textColor: Number(pnl) <= -0.0001 ? 'danger' : (Number(pnl) >= 0.0001 ? 'success' : 'dark'),
      arrow: Number(pnl) <= -0.0001 ? 'bi-arrow-down-short fs-2' :
        (Number(pnl) >= 0.0001 ? 'bi-arrow-up-short fs-2' : 'bi-arrows-collapse me-1 fs-3'),
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
        'col-12 col-sm-4 col-xxl-12 pt-6'
    },
    {
      name: 'Total Equity',
      icon: 'financial-schedule',
      value: equity,
      iconColor: 'info',
      styleList: VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
        'col-12 col-sm-4 col-xxl-12 pt-6'
    },
  ]

  return (
    <>
      <Row>
        {(isInvestmentDetails ? investmentDataMapper : walletDataMapper).map((item, idx) => {
          return (
            <Col key={idx} className={clsx(item.styleList)}>
              <div className="card border-0 min-h-100">
                <div className='card-body d-flex align-items-center p-0'>
                  <div className='d-flex flex-stack align-items-start pt-5 pb-6 px-4 flex-grow-1'>
                    <div className='d-flex flex-column '>
                      <span className='text-gray-800 fs-4 fw-bold'>{item.name}</span>
                      <span
                        className={`text-${item.textColor ? item.textColor : 'gray-700'} fw-bold mt-2 fs-1 text-nowrap`}>
                        {(item.name === 'Total PNL' && Number(pnl) >= 0.0001) ? '+' : ((item.name === 'Total PNL' && Number(pnl) <= -0.0001) ? '-' : '')}
                        ${Intl.NumberFormat().format(item.value[0])}
                        <span className='text-muted fs-3'>.{item.value[1]}</span>
                      </span>

                      {(item.arrow && item.name === 'Total PNL') ?
                        <span className="d-none d-sm-flex d-flex d-lg-none">
                          <span className="fs-3 mt-2 fw-bold text-nowrap">
                            <span
                              className={`badge badge-light-${item.textColor} fw-bold d-inline-flex fs-5`}>
                                <i
                                  className={`bi ${item.arrow} p-0 fw-bold text-${item.iconColor}`}
                                ></i>
                              <span className={`text-${item.iconColor}`}>{Number(pnlPct).toFixed(4)}%</span>
                            </span>
                          </span>
                        </span> : ''
                      }
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                      <KTIcon iconName={item.icon} className={`fs-2x text-${item.iconColor}`}/>
                      {(item.arrow && item.name === 'Total PNL') ?
                        <span className="d-flex d-sm-none d-lg-flex">
                          <span className="text-success fs-3 mt-2 fw-bold text-nowrap">
                            <span
                              className={`badge badge-light-${item.textColor} fw-bold d-inline-flex fs-5`}>
                                <i
                                  className={`bi ${item.arrow} p-0 fw-bold text-${item.iconColor}`}
                                ></i>
                              <span className={`text-${item.iconColor}`}>{Number(pnlPct).toFixed(4)}%</span>
                            </span>
                          </span>
                        </span> : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )
        })
        }
      </Row>
    </>
  )
}

export default MyWalletDetailsWidget
