import React, {useEffect, useState} from "react"
import {clsx} from "clsx"
// import step1Img from "@metronic/Components/Securities/images/Step_1.png"
// import step2Img from "@metronic/Components/Securities/images/step_2.png"
// import step3Img from "@metronic/Components/Securities/images/step_3.png"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import _, {capitalize} from "lodash"
import moment from "moment-timezone"
import {formatSecuritiesPrice, toTwoPrecision} from "@metronic/helpers/utils.jsx"
import {SecurityType} from '@metronic/Enums/Securities/SecurityType.js'

export const StepsImages = ({blockId, activeStepTab}) => {
  const {tabData} = useSecuritiesContext()
  const [data, setData] = useState([])

  useEffect(() => {
    if (tabData && blockId) {
      setData(tabData.find(i => i.uuid === blockId)?.steps_tabs_list)
    }
  }, [tabData, blockId])

  return <>
    {(data && data.length) ?
      data.map((item, idx) => {
        return (
          <img
            key={idx}
            className={clsx('mw-225px ps-0 ps-md-2', activeStepTab === item.uuid ? 'd-block' : 'd-none')}
            src={item.img}
            alt=""
          />
        )}
      ) : null
    }
  </>
}

export const allListLink = () => {
  const {activeTab, setActiveTab, allListTab, tableRef} = useSecuritiesContext()

  const handleClick = (e) => {
    e.preventDefault()

    if (!allListTab) return

    if (allListTab !== activeTab) {
      setActiveTab(allListTab)
    }

    if (allListTab && tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
      }, 200)
    }
  }

  return <div className="py-3 mb-5">
    <a
      href="#"
      className="text-success fs-4 btn btn-active-light-success ps-2 pe-0 py-0 m-0"
      onClick={(e) => handleClick(e)}
    >
      See full list <i className="bi bi-chevron-right fs-4 ps-1 text-success"></i>
    </a>
  </div>
}

export const blockCardTitle = (title) => {
  return (
    <>
      <h2 className="text-primary">{title}</h2>
      <div className="separator mb-4 border-1 border-gray-500"></div>
    </>
  )
}

export const SecurityDetailsTabs = ({activeTableTab, toggleTab, isOwnSecurity, selectedSecurities}) => {
  let ticker = ''

  if (selectedSecurities?.meta && selectedSecurities?.meta['ticker'] && selectedSecurities?.meta['ticker']['value']) {
    ticker = selectedSecurities?.meta['ticker']['value']
  } else {
    ticker = ''
  }

  const tabsArr = [
    { tab_id: 'overview' },
  ]

  if (ticker) {
    tabsArr.push({ tab_id: 'stats' })
  }

  if (
    selectedSecurities?.meta?.['about']?.['value'] ||
    (selectedSecurities.type !== SecurityType.BOND && selectedSecurities.type !== SecurityType.INDEX)
  ) {
    tabsArr.push({ tab_id: 'about' });
  }

  if (isOwnSecurity) {
    tabsArr.push({ tab_id: 'details' })
  }

  const classList = 'nav-link me-0 cursor-pointer fw-bold py-1 px-2 rounded-1'

  return (
    <div className="mb-2 hover-scroll-x pb-4">
      <div className="d-grid">
        <ul className="nav nav-tabs nav-line-tabs nav-stretch flex-nowrap border-0 text-nowrap">
          {tabsArr ? tabsArr.map((tab, idx) => {
            return (
              <li className="nav-item" key={idx}>
                <a
                  className={clsx(
                    classList,
                    activeTableTab === String(tab.tab_id) ? 'active text-primary bg-secondary' : 'text-gray-700 bg-light-secondary')}
                  onClick={(e) => {
                    e.preventDefault()
                    toggleTab(String(tab.tab_id))
                  }}
                >
                  {capitalize(tab.tab_id)}
                </a>
              </li>
            )
          }) : null}
        </ul>
      </div>
    </div>
  )
}

export const detailsDisclaimerText = (email) => {
  return (
    <div className="d-flex justify-content-center border-top mt-5 pt-10">
      <div className="text-gray-600 text-center w-100 w-sm-50 w-md-75">
        <p className="fw-bold">
          Past performance is not indicative of future results.
        </p>
        <p>
          Investing in securities involves significant risks, and there is always the potential for loss of capital.
          The value of securities can fluctuate due to various factors such as market conditions, interest rates,
          and changes in economic, political, or regulatory environments. It is important to conduct thorough research
          and consider your risk tolerance before engaging in any form of trading or investment.
        </p>
        {email ?
          <p>
          Would you like to
          <a href={`mailto:${email}`} className="mx-1 text-decoration-underline fw-bold"
             target="_blank">consult</a>
          with a qualified financial advisor to understand the potential risks
          associated with securities trading?
        </p> : null
        }
      </div>
    </div>
  )
}

const prepareBondPrivateFields = (acquiredSecurity, selectedSecurities, setTimeLeft, setSecurityData) => {
  const maturityDate = moment(selectedSecurities.meta['maturity_date']?.value)
  let totalDividends = 0
  let totalCurrentPrice = 0
  let totalParValue = 0

  acquiredSecurity.transfers.forEach(item => {
    const quantity = item?.meta['quantity'] || 0
    const currentPrice = item?.meta['current_price'] || 0
    const couponRate = item?.meta['coupon_rate'] || 0
    totalCurrentPrice += currentPrice * quantity
    totalParValue += (item?.meta['par_value'] || 0) * quantity

    const purchaseDate = moment(item.created_at)
    const dividendsPerDay = (couponRate && currentPrice) ?
      ((Number(couponRate) / 100) * Number(currentPrice) / 365) : 0

    totalDividends += (purchaseDate.diff(moment(), 'days') * -1) * dividendsPerDay
  })

  const averageCurrentPrice = totalCurrentPrice / Number(acquiredSecurity.quantity)
  const averageParValue = totalParValue / Number(acquiredSecurity.quantity)

  const privateFields = [
    {quantity: {label: 'Total quantity', value: acquiredSecurity.quantity}},
    {dividends: {label: 'Total coupon payments', value: toTwoPrecision(totalDividends)}},
    {time_left: {label: 'Times left', value: ''}},
    {app: {label: 'Average Purchase Price', value: averageCurrentPrice.toFixed(2)}},
    {average_par_value: {label: 'Average Par Value', value: averageParValue.toFixed(2)}},
    {coupon_rate: {label: 'Coupon rate', value: <span>{selectedSecurities.meta['coupon_rate']?.value}%</span> || '-'}},
    {maturity_date: {label: 'Maturity date', value: selectedSecurities.meta['maturity_date']?.value || '-'}},
    {frequency: {label: 'Frequency', value: capitalize(selectedSecurities.meta['frequency']?.value) || '-'}}
  ]

  setSecurityData(privateFields)

  const updateCountdown = () => {
    const now = moment()
    const duration = moment.duration(maturityDate.diff(now))
    setTimeLeft({
      years: duration.years(),
      months: duration.months(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    })
  }

  clearInterval(window.countdownInterval)
  window.countdownInterval = setInterval(updateCountdown, 1000)
  updateCountdown()
  return () => clearInterval(window.countdownInterval)
}

const prepareStockAndIndexPrivateFields = (acquiredSecurity, selectedSecurities, setSecurityData) => {
  let totalInvestments = 0

  acquiredSecurity.transfers.forEach(item => {
    totalInvestments += (-Number(item.base_amount))
  })

  const currentPrice = selectedSecurities?.meta['current_price']?.value || 0
  const averagePrice = Number(totalInvestments / acquiredSecurity.quantity)
  const pctChange = (((Number(currentPrice) - averagePrice) / averagePrice) * 100).toFixed(2)
  const totalProfitLoss = ((Number(currentPrice) * Number(acquiredSecurity.quantity)) - totalInvestments).toFixed(2)

  const privateFields = [
    {quantity: {label: 'Total quantity', value: acquiredSecurity.quantity}},
    {total_investments: {label: 'Total investments', value: (totalInvestments || 0).toFixed(2)}},
    {average_price: {label: 'Average Purchase Price', value: averagePrice.toFixed(2)}},
    {price_change_pct: {
        label: 'Price Change (%)',
        value: <span className={`fs-4 badge badge-light-${Number(pctChange) > 0 ? 'success' : (Number(pctChange) < 0 ? 'danger' : 'secondary')}`}>
                {Number(pctChange) !== 0 ? <i className={`bi bi-arrow-${Number(pctChange) > 0 ? 'up' : 'down'}-short fs-2 p-0 fw-bolder text-${Number(pctChange) > 0 ? 'success' : 'danger'}`}></i> : ''}
          {pctChange}%
              </span>
      }
    },
    {tpl: {
      label: 'PNL',
        value: <span className={`text-${totalProfitLoss > 0 ? 'success' : (totalProfitLoss < 0 ? 'danger' : 'muted')}`}>
                <span>{(totalProfitLoss < 0) ? '-' : (totalProfitLoss > 0 ? '+' : '')}</span>
          {
            formatSecuritiesPrice(selectedSecurities?.meta['payout_asset']['payout_asset_code'],
              (totalProfitLoss < 0) ? (totalProfitLoss * -1) : totalProfitLoss)
          }
              </span>
      }
    },
    {cpv: {label: 'Current Investments Value', value: Number(Number(totalInvestments) + Number(totalProfitLoss)).toFixed(2)}},
  ]

  setSecurityData(privateFields)
}

export const preparePrivateFields = (
  selectedSecurities,
  acquiredSecurity,
  isOwnDetails,
  timeLeft, setTimeLeft,
  setSecurityData
) => {
  if (selectedSecurities?.type === SecurityType.BOND) {
    return prepareBondPrivateFields(acquiredSecurity, selectedSecurities, setTimeLeft, setSecurityData)
  } else if (selectedSecurities?.type === SecurityType.STOCK || selectedSecurities?.type === SecurityType.INDEX) {
    return prepareStockAndIndexPrivateFields(acquiredSecurity, selectedSecurities, setSecurityData)
  } else {
    return []
  }
}
