import React, {createContext, useContext, useEffect, useRef, useState} from 'react'
import {getIndicesRates, getStocksData} from "@metronic/api/securities.js"
import _ from 'lodash'
import {selectListData} from "@metronic/api/general.js"
import {SecurityType} from '@metronic/Enums/Securities/SecurityType.js'

const SecuritiesContext = createContext({})

export const useSecuritiesContext = () => {
  return useContext(SecuritiesContext)
}

export const fetchData = async (securitiesList, assetsList, setSecuritiesList, setIsSecuritiesListReady) => {
  if (!_.isEmpty(securitiesList)) {
    // let newSecuritiesList = securitiesList
    //
    // if (!_.isEmpty(newSecuritiesList)) {
    //   let stocksTickerList =
    //     newSecuritiesList
    //       .filter(i => i.meta?.ticker?.value && i.type === SecurityType.STOCK)
    //       .map(item => item.meta?.ticker?.value)
    //       .filter(i => i !== !_.isEmpty(i))
    //
    //   if (!_.isEmpty(stocksTickerList)) {
    //     const stockData = await getStocksData({ 'stock_tickers': stocksTickerList })
    //
    //     newSecuritiesList.map(item => {
    //       let meta = item.meta
    //       let ticker = meta?.ticker?.value
    //
    //       if (!stocksTickerList.includes(String(ticker))) {
    //         return item
    //       }
    //
    //       let stockOverviewData = stockData?.stock_overview_data.find(i => i.stock_ticker === ticker)
    //       let stockPrice = stockData.stock_price.find(i => i.stock_ticker === ticker)
    //       let stocksData = stockData.stock_data.filter(i => i.stock_ticker === ticker)
    //
    //       if (!meta['current_price']['value'] && ticker && stockPrice?.highest_data?.c) {
    //         item.meta['current_price']['value'] = stockPrice?.highest_data?.c
    //       }
    //
    //       if (!meta['payout_asset']['value'] && stockOverviewData?.data) {
    //         const asset = assetsList.find(i => i.label === stockOverviewData?.data['Currency'])
    //
    //         if (!_.isEmpty(asset)) {
    //           item.meta['payout_asset']['value'] = asset.value
    //           item.meta['payout_asset']['payout_asset_code'] = asset.label
    //         }
    //       }
    //
    //       item['api_data'] = {
    //         stock_data: !_.isEmpty(stocksData) ? [...stocksData] : [],
    //         stock_price: !_.isEmpty(stockPrice) ? stockPrice : [],
    //         stock_overview_data: !_.isEmpty(stockOverviewData) ? stockOverviewData : []
    //       }
    //
    //       return item
    //     })
    //   }
    // }

    const updatedList = await Promise.all(
      securitiesList.map(async (item) => {
        let type = item.type

        if (type !== SecurityType.INDEX) {
          return item
        }

        let meta = item.meta
        let ticker = meta?.ticker?.value || ''
        let currentPrice = meta?.current_price?.value || ''

        if (!ticker) {
          return item
        }

        if (type === SecurityType.INDEX) {
          const r = await getIndicesRates({ index_ticker: ticker, range: 'month' });
          item['api_data'] = { ...r }

          if (!currentPrice && r) {
            item.meta['current_price']['value'] = r?.index_price?.data?.close
          }
        }

        return item
      })
    )

    setSecuritiesList(updatedList)
    setIsSecuritiesListReady(true)
  }
}

export const setStocksApiData = (securitiesList, setSecuritiesList, assetsList, securitiesApiData) => {
  if (!_.isEmpty(securitiesList.filter(i => i.type === SecurityType.STOCK)) && !_.isEmpty(securitiesApiData)) {

    const newList = securitiesList.map(item => {
      let meta = item.meta
      let ticker = meta?.ticker?.value

      if (!ticker || item?.type !== SecurityType.STOCK) {
        return item
      }

      let stockOverviewData = securitiesApiData?.stock_overview_data.find(i => i.stock_ticker === ticker)
      let stockPrice = securitiesApiData.stock_price.find(i => i.stock_ticker === ticker)
      let stocksData = securitiesApiData.stock_data.filter(i => i.stock_ticker === ticker)

      if (!meta?.current_price?.value && ticker && stockPrice?.highest_data?.c) {
        item.meta['current_price']['value'] = stockPrice?.highest_data?.c
      }

      if (!meta?.payout_asset?.value && stockOverviewData?.data) {
        const asset = assetsList.find(i => i.label === stockOverviewData?.data['Currency'])

        if (!_.isEmpty(asset)) {
          item.meta['payout_asset']['value'] = asset.value
          item.meta['payout_asset']['payout_asset_code'] = asset.label
        }
      }

      item['api_data'] = {
        stock_data: !_.isEmpty(stocksData) ? [...stocksData] : [],
        stock_price: !_.isEmpty(stockPrice) ? stockPrice : [],
        stock_overview_data: !_.isEmpty(stockOverviewData) ? stockOverviewData : []
      }

      return item
    })

    setSecuritiesList(newList)
  }
}

export const SecuritiesProvider = ({children, page_data, customer_securities, ...props}) => {
  const [activeTab, setActiveTab] = useState('')
  const [securitiesList, setSecuritiesList] = useState([])
  const [activeBondId, setActiveBondId] = useState(0)
  const [activeBond, setActiveBond] = useState({})
  const [tabList, setTabList] = useState([])
  const [allListTab, setAllListTab] = useState('')
  const [ownListTab, setOwnListTab] = useState('')
  const [tabData, setTabData] = useState([])
  const [purchaseList, setPurchaseList] = useState([])
  const tableRef = useRef(null)
  const [ownSelectedSecurities, setOwnSelectedSecurities] = useState({})
  const [isSecuritiesListReady, setIsSecuritiesListReady] = useState(false)
  const [assetsList, setAssetsList] = useState([])
  const [securitiesApiData, setSecuritiesApiData] = useState([])

  const [data, setData] = useState([])

  useEffect(() => {
    selectListData('assets_list').then(r => {
      setAssetsList(r)
    })
  }, [])

  useEffect(() => {
    if (page_data) {
      setData(page_data)

      if (page_data?.securities?.length) {
        setSecuritiesList(page_data.securities)
      }

    }
  }, [page_data])

  useEffect(() => {
    setSecuritiesApiData(props?.securities_data)
  }, [props])

  useEffect(() => {
    if (assetsList?.length && !_.isEmpty(securitiesApiData)) {
      setStocksApiData(securitiesList, setSecuritiesList, assetsList, securitiesApiData)
    }
  }, [assetsList, securitiesApiData])

  useEffect(() => {
    if (securitiesList.length && assetsList.length && !isSecuritiesListReady) {
      fetchData(securitiesList, assetsList, setSecuritiesList, setIsSecuritiesListReady)
    }
  }, [securitiesList, assetsList])

  useEffect(() => {
    if (customer_securities && customer_securities.length) {
      setPurchaseList(customer_securities)
    }
  }, [customer_securities])

  useEffect(() => {
    if (data && data.tabs) {
      setTabList(data.tabs.map((item) => {
        return ({
          id: item.id,
          title: item.title
        })
      }))

      const allListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list === null))?.id

      const ownListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list))?.id

      setAllListTab(allListBlock || '')
      setOwnListTab(ownListBlock || '')
    }
  }, [data])

  useEffect(() => {
    if (tabList && tabList.length && !activeTab) {
      setActiveTab(String(tabList[0]?.id))
    }
  }, [tabList])

  useEffect(() => {
    if (data && activeTab && activeTab.length) {
      setTabData(data.tabs.find(tab => tab.id === activeTab)?.blocks)
    }
  }, [data, activeTab])

  useEffect(() => {
    if (ownSelectedSecurities?.name && (activeTab !== ownListTab)) {
      setOwnSelectedSecurities({})
    }
  }, [activeTab, ownSelectedSecurities])

  return (
    <SecuritiesContext.Provider
      value={{
        ...props,
        activeTab, setActiveTab,
        securitiesList, setSecuritiesList,
        activeBondId, setActiveBondId,
        activeBond, setActiveBond,
        tabList, setTabList,
        ownSelectedSecurities, setOwnSelectedSecurities,
        data,
        allListTab,
        ownListTab,
        tabData,
        tableRef,
        purchaseList,
        isSecuritiesListReady
      }}>
      {children}
    </SecuritiesContext.Provider>
  )
}
