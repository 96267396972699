import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row} from "reactstrap"
import {clsx} from "clsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"

const selectOptions = [
  {name: 'change_6m_pct' ,value: '0.5', label: '6 month'},
  {name: 'change_1y_pct' ,value: '1', label: '1 year'},
  // {value: '1.5', label: '1.5 years'},
  {name: 'change_5y_pct' ,value: '5', label: '5 years'},
  // {value: '2.5', label: '2.5 years'},
  // {value: '3', label: '3 years'}
]

const CalculationBlock = ({blockId, annualGrowth}) => {
  const [investmentValue, setInvestmentValue] = useState(Number())
  const {
    activeBondId,
    activeBond,
    tabData
  } = useSecuritiesContext()
  const [selectedCalcOptions, setSelectedCalcOptions] = useState(1)
  const [profit, setProfit] = useState(0)
  const [rangeOptions, setRangeOptions] = useState([])
  const [assetCode, setAssetCode] = useState('')
  const [annualReturn, setAnnualReturn] = useState(0)

  const investmentAmountChangeHandler = (e) => {
    setInvestmentValue(e.target.value)
  }

  const calcProfit = () => {
    let totalProfit = 0.00
    if (activeBondId && activeBond?.type !== SecurityType.STOCK) {
      const annualYield = Number(annualReturn) / 100
      const yearsValue = annualYield * investmentValue

      // totalProfit = Number(investmentValue) * Math.pow((1 + (Number(annualYield))), Number(selectedCalcOptions)) - Number(investmentValue)
      totalProfit = yearsValue * selectedCalcOptions
    } else {
      let pctValue = activeBond?.api_data?.stock_price[
        selectOptions.find(i => Number(i.value) === Number(selectedCalcOptions))?.name
        ] || 0.00

      totalProfit = (Number(investmentValue) * (1 + (Number(pctValue) / 100))) - Number(investmentValue)
    }

    setProfit(Number(totalProfit).toFixed(2))
  }

  useEffect(() => {
    calcProfit()
  }, [activeBondId, investmentValue, selectedCalcOptions, annualReturn])

  useEffect(() => {
    if (tabData && blockId) {
      const range = tabData.find(i => i.uuid === blockId)?.value_range

      if (range && range.length) {
        setRangeOptions(range)
        setInvestmentValue(range[0] || 1000)
      }
    }
  }, [tabData, blockId])

  useEffect(() => {
    if (Object.keys(activeBond).length) {
      setAssetCode(activeBond.meta['payout_asset']?.payout_asset_code || '')
    }
  }, [activeBond])

  useEffect(() => {
    if (Object.keys(activeBond).length) {
      if (activeBond?.type === SecurityType.BOND) {
        setAnnualReturn(activeBond?.meta?.['coupon_rate']?.value || 0)
      } else if (activeBond?.type === SecurityType.INDEX) {
        setAnnualReturn(annualGrowth || 0)
      } else if (activeBond?.type === SecurityType.STOCK) {
        setAnnualReturn(activeBond?.api_data?.stock_price?.change_1y_pct)
      } else {
        setAnnualReturn(0)
      }
    }
  }, [annualGrowth, activeBond])

  return (
    <>
      <Row className="g-5 g-xl-10 d-flex align-items-center">
        <Col className={clsx('', VITE_APP_VARIANT === 'light' ?
          'col-12 col-xxl-6' : 'col-12 col-lg-6')}>
          <Card>
            <CardBody className="p-0 pt-4">
              <div className="d-flex flex-column justify-content-end">
                <label className="d-flex justify-content-between pb-3 px-2">
                  <div className="text-gray-700 fs-3 fw-bold pe-2">
                    Amount you want to invest
                  </div>
                  <div className="fw-bold d-flex align-items-center">
                    <input
                      className="text-end border-0 mw-80px p-0 m-0 fs-6 fw-bold text-gray-700"
                      style={{height: '35px'}}
                      id="amount-input"
                      type="input"
                      value={investmentValue}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setInvestmentValue(0)
                        }
                      }}
                      onClick={(e) => {
                        if (e.target.value === '0') {
                          setInvestmentValue('')
                        }
                      }}
                      onChange={(e) => {
                        setInvestmentValue(e.target.value)
                      }}
                    />
                    <span className="d-inline-flex align-items-center ms-2 fs-6 fw-bold" style={{height: '35px'}}>{assetCode}</span>
                  </div>
                  {/*<div className="text-gray-800 fs-3 fw-bold">{Number(investmentValue).toFixed(2)} {assetCode}</div>*/}
                </label>
                <input
                  id="range-slider"
                  type="range"
                  min={rangeOptions[0] || 1000}
                  max={rangeOptions[1] || 100000}
                  value={investmentValue}
                  onChange={investmentAmountChangeHandler}
                  className="custom-range-slider"
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className={clsx('h-65px', VITE_APP_VARIANT === 'light' ?
          'col-12 col-xxl-6' : 'col-12 col-lg-6')}>
          <Card className="h-100">
            <CardBody className="p-0 pt-5 h-100">
              <div className="d-flex justify-content-between px-2">
                <span className="text-gray-700 text-nowrap fs-3 fw-bold pe-2">
                  Investment period
                </span>
                <select
                  className="text-gray-700 fw-bold fs-4 w-100 cursor-pointer border-0 bg-body"
                  onChange={(e) => setSelectedCalcOptions(Number(e.target.value))}
                  value={selectedCalcOptions}
                >
                  {selectOptions.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="col-12">
          <h2 className="text-gray-700 mb-5">Your profit</h2>
          <div className="d-column d-sm-flex justify-content-sm-between border rounded-2 p-3 ">
            <div className="d-flex flex-column w-sm-25">
              <span className="text-gray-600 fs-5">Security name</span>
              <h2 className="pe-2 text-truncate">{activeBond?.name ? activeBond?.name : 'Nothing selected'}</h2>
            </div>

            <div className="d-flex flex-column w-sm-25">
              <span className="text-gray-600 fs-5 pe-2">Percentages</span>
              <h2>
                {activeBond?.type === SecurityType.STOCK ?
                  (activeBond?.api_data?.stock_price[selectOptions.find(i => Number(i.value) === Number(selectedCalcOptions))?.name] || 0.00) :
                  annualReturn
                } %
              </h2>
            </div>

            <div className="d-flex flex-column w-sm-25">
              <span className="text-gray-600 fs-5 pe-2 text-truncate">Investment period</span>
              <h2>{selectedCalcOptions === 0.5 ? '6 month' : selectedCalcOptions} {(Number(selectedCalcOptions) > 1 && selectedCalcOptions !== 0.5) ? 'years' :
                selectedCalcOptions === 0.5 ? '' : 'year'}</h2>
            </div>

            <div className="d-flex flex-column pe-2 ">
              <span className="text-gray-600 fs-5 text-truncate">Your potential income</span>
              <h2>{profit} {assetCode}</h2>
            </div>

          </div>
        </Col>

        <Col className="col-12 mt-3">
          <p className="text-muted p-3 bg-light-warning rounded-2">
            <span className="fw-bold">Disclaimer:</span> The calculations provided by this tool are
            approximate and
            intended for informational purposes only. The actual returns on your investments may vary
            from the estimates given, based on various factors and market conditions.
            Please consult with a financial advisor for more accurate and personalized advice.
          </p>
        </Col>
      </Row>
    </>
  )
}

export default CalculationBlock
