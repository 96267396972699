// import {useIntl} from 'react-intl'
// Core
import React, {useEffect, useState} from "react"
import {Row, Col} from "reactstrap"
import {Link, usePage} from '@inertiajs/react'
import clsx from "clsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {useThemeMode} from "@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider"
// Widget
import {StatisticsWidget5} from '@metronic/_metronic/partials/widgets'
import {AdvancedRealTimeChart} from "react-ts-tradingview-widgets"
import {CryptoDepositWidget, CryptoCardWidget} from '@metronic/Components/CustomWidgets'
// Components
import {KTIcon} from "@metronic/_metronic/helpers"
import { Toolbar } from '@metronic/_metronic/layout/components/toolbar/Toolbar'
import { Content } from '@metronic/_metronic/layout/components/Content'

const DashboardContent = () => {
  const {props} = usePage()
  const accountBalance = usePage().props.account_balance.total
  const availableBalance = usePage().props.account_balance.available
  const lockedBalance = usePage().props.account_balance.locked
  const {mode} = useThemeMode()
  const [chartKey, setChartKey] = useState(1)

  useEffect(() => {
    setChartKey(prevKey => prevKey + 1)
  }, [mode])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          {/* Crypto widgets START */}
          <Col className={clsx('', VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-8' : 'col-12 col-xl-8')}>
            <CryptoCardWidget />
          </Col>
          {/* Crypto widget END */}

          {/* Deposit form START */}
          <Col className={clsx('', VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-4' : 'col-12 col-xl-4')}>
            <CryptoDepositWidget />
          </Col>
          {/* Deposit form END */}
        </Row>

        {/* Account balance row START */}
        <Row className='g-5 g-xl-10 mb-5 mb-xl-10'>
          <Col className="col-12 col-sm-6 col-xxl-3">
            <StatisticsWidget5
              className="mb-md-0 text-center cursor-default"
              svgIcon='chart-simple'
              iconColor='primary'
              title='Account Balance'
              description={`${Intl.NumberFormat().format(accountBalance)} USD`}
              descriptionColor='muted'
              descriptionFontSize='3'
              descriptionFontWeight='bold'
            />
          </Col>
          <Col className="col-12 col-sm-6 col-xxl-3">
            <StatisticsWidget5
              className="mb-md-0 text-center cursor-default"
              svgIcon='chart-simple-3'
              iconColor='danger'
              title='Locked Balance'
              description={`${Intl.NumberFormat().format(lockedBalance)} USD`}
              descriptionColor='muted'
              descriptionFontSize='3'
              descriptionFontWeight='bold'
            />
          </Col>
          <Col className="col-12 col-sm-6 col-xxl-3">
            <StatisticsWidget5
              className="mb-md-0 text-center cursor-default"
              svgIcon='chart-simple-2'
              iconColor='success'
              title='Available Balance'
              description={`${Intl.NumberFormat().format(availableBalance)} USD`}
              descriptionColor='muted'
              descriptionFontSize='3'
              descriptionFontWeight='bold'
            />
          </Col>
          <Col className="col-12 col-sm-6 col-xxl-3">
            <Link href="/wallet" className="card hoverabletext-center h-100">
              <div className='card-body text-center px-1 px-sm-3 py-1 py-sm-5 h-100'>
                <KTIcon iconName='chart-pie-simple' className='text-primary pb-1 fs-3x ms-n1'/>
                <div className='fw-bold fs-1 mb-2 mt-4'>
                  My Wallet
                  <i className="bi bi-arrow-up-right-square-fill fs-2x opacity-75 text-primary ms-3"></i>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        {/* Account balance row END */}

        {/* Crypto chart row START */}
        <Row style={{height: "500px"}} className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col>
            <div className="h-100">
              <AdvancedRealTimeChart
                key={chartKey}
                container_id={'tradingview_widget_wrapper'}
                copyrightStyles={{parent: {display: 'none'}}}
                width={'100%'}
                height={'100%'}
                theme={mode === 'system' ? 'light' : mode}
                symbol="COINBASE:BTCUSD"
              />
            </div>
          </Col>
        </Row>
        {/* Crypto chart row END */}

      </Content>
    </>
  )
}

export default DashboardContent
