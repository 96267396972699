import React, {useEffect, useRef, useState} from 'react'
import {Col, Form, Input, Row} from 'reactstrap'
import {toast} from "react-toastify"
import {isTwoPrecision, toTwoPrecision, assetFullName} from "@metronic/helpers/utils"
import {Tooltip} from "react-tooltip"
import {useTranslation} from "react-i18next"
import {router, useForm, usePage} from "@inertiajs/react"
import {selectListData} from "@metronic/api/general.js"
import _ from "lodash"
import {calculate, getExchangeFee} from "@metronic/api/exchange.js"

let requestTimer = null

const ExchangeWidgetForm = ({formSentHandler, isReal}) => {
  const {props} = usePage()
  const [accountBalance, setAccountBalance] = useState([])
  const [assets, setAssets] = useState([])
  const calculateSide = useRef('sell')
  const [exchangeFee, setExchangeFee] = useState('')

  const [sellAsset, setSellAsset] = useState(0)
  const [buyAsset, setBuyAsset] = useState('')
  const [assetBalance, setAssetBalance] = useState(0)
  const [assetUsdBalance, setAssetUsdBalance] = useState(0)
  const [sellAssetSelect, setSellAssetSelect] = useState(null)
  const [sellAssetUsdPrice, setSellAssetUsdPrice] = useState(0)
  const [buyAssetSelect, setBuyAssetSelect] = useState(null)
  const [sellAmount, setSellAmount] = useState('')
  const [buyAmount, setBuyAmount] = useState('')
  const [buyAmountInUsd, setBuyAmountInUsd] = useState(0)
  const [totalPayAmount, setTotalPayAmount] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  const [updateSellAssetBalance, setUpdateSellAssetBalance] = useState(1)
  const {t} = useTranslation()

  const {data, setData, post, processing, errors, reset} = useForm({
    sell_asset_id: '',
    buy_asset_id: '',
    sell_amount: '',
    buy_amount: '',
    use_unlocked_balance: '',
  });

  const calculateAmount = () => {
    requestTimer && clearTimeout(requestTimer)

    if (sellAmount || buyAmount) {
      let amount = 0
      let sellAsset = sellAssetSelect || accountBalance[0]?.asset_id
      let buyAsset = buyAssetSelect || assets[0]?.value

      if (calculateSide.current === 'buy') {
        amount = buyAmount || 0
      } else {
        amount = sellAmount || 0
      }

      const calcData = {
        sell_asset: sellAsset,
        buy_asset: buyAsset,
        amount: amount,
        side: calculateSide.current,
        available_balance: !!isReal,
      }

      requestTimer = setTimeout(() => {

        calculate(calcData
        ).then(r => {
          if (r.success) {
            // setBuyAmountInUsd(toTwoPrecision(r?.buy_amount_in_usd))
            setBuyAmountInUsd(r?.buy_amount_in_usd)
            setTotalPayAmount((Number(r?.sell_amount) + Number(r?.sell_fee_amount)).toFixed(8) * 1 || 0)
            setFeeAmount(Number(r?.sell_fee_amount).toFixed(8) * 1 || 0)
            let assetCode = assets?.find(i => Number(i.value) === Number(sellAsset || 1))?.label || 'BTC'

            if (calculateSide.current === 'buy') {
              // setSellAmount(isTwoPrecision(assetCode, r?.sell_amount || 0))
              setSellAmount(r?.sell_amount || 0)
              calculateSide.current = 'sell'
              // calculateLockedAmount()
            } else {
              // setBuyAmount(isTwoPrecision(buyAsset?.label, r?.buy_amount || 0))
              setBuyAmount(r?.buy_amount || 0)
            }
          }
        })
      }, 300)
    }
  }

  const sellSelectHandler = (e) => {
    let val = e.target.options[e.target.selectedIndex].value

    setSellAssetSelect(val)
  }

  const buySelectHandler = (e) => {
    let val = e.target.options[e.target.selectedIndex].value

    setBuyAssetSelect(val)
  }

  const sellAmountInputHandler = (e) => {
    calculateSide.current = 'sell'
    let val = e.target?.value.replace(/,/g, '.')
    let amount = val < 0 ? Math.abs(val) : val

    setSellAmount(amount)
  }

  const buyAmountInputHandler = (e) => {
    calculateSide.current = 'buy'
    let val = e.target?.value.replace(/,/g, '.')
    let amount = val < 0 ? Math.abs(val) : val

    setBuyAmount(amount)
  }

  const resetForm = () => {
    setSellAmount('')
    setBuyAmount('')
    setBuyAmountInUsd('')
    setFeeAmount(0.00)
    setTotalPayAmount(0.00)
    setSellAssetUsdPrice('')
  }

  const submitHandler = (e) => {
    e.preventDefault()

    post('/exchange', {
      preserveScroll: true,
      onSuccess(r) {
        if (r.props?.success) {
          toast.success(r.props?.message)
          router.reload({only: ['account_balance', 'flash']})
        }
        reset()
        resetForm()
      }
    });
  }

  const calculateMaxSumHandler = (e) => {
    e.preventDefault()

    if (sellAsset && assetBalance) {
      setSellAmount(isTwoPrecision(sellAsset, assetBalance))
      // setSellAmount(assetBalance)
    } else {
      toast.warning(t('Select the asset you want to sell with a balance greater than zero.'))
    }
  }

  useEffect(() => {
    if (sellAsset) {
      let asset = accountBalance.find(i => i.code === sellAsset)

      if (asset) {
        setSellAssetSelect(asset.asset_id)
      }
    }
  }, [sellAsset, accountBalance, isReal])

  useEffect(() => {
    selectListData('assets_list').then(r => setAssets(r))
    getExchangeFee().then(r => setExchangeFee(r))
  }, [])

  useEffect(() => {
    if (_.size(props?.account_balance)) {
      setAccountBalance(props.account_balance.all)
    }
  }, [props])

  useEffect(() => {
    if (assets?.length && accountBalance?.length && !data.buy_asset_id) {
      assets.every(asset => {
        if (accountBalance?.find(i => i.code === asset.label)) {
          setData(data => ({ ...data, buy_asset_id: asset.value}));
          return false
        }
      })
    }

    if (assets?.length && !data.sell_asset_id) {
      setData(data => ({ ...data, sell_asset_id: assets[0]?.value}));
    }
  }, [assets, accountBalance])

  useEffect(() => {
    if (Number(sellAmount)) {
      calculateAmount()
    } else if (Number(!sellAmount)) {
      setSellAmount('')
      setFeeAmount('0.00')
      setBuyAmountInUsd('0.00')
    }
    setData('sell_amount', sellAmount)
  }, [sellAmount])

  useEffect(() => {
    if (Number(buyAmount)) {
      calculateAmount()
    } else if (Number(!buyAmount)) {
      setBuyAmount('')
      setTotalPayAmount(0.00)
    }
    setData('buy_amount', buyAmount)
  }, [buyAmount])

  useEffect(() => {
    calculateAmount()
  }, [sellAssetSelect, buyAssetSelect])

  useEffect(() => {
    let balance = accountBalance.filter(i => !!i.is_real === !!isReal)
    let currentSellAsset = balance?.find(i => {
      return Number(i.asset_id) === Number(sellAssetSelect || accountBalance[0]?.asset_id)
    })

    setSellAsset(currentSellAsset?.code)
    setAssetBalance(currentSellAsset?.balance || 0)
    setAssetUsdBalance(currentSellAsset?.balance * currentSellAsset?.price || 0)
    setSellAssetUsdPrice(currentSellAsset?.price || 0)

    setData('sell_asset_id', sellAssetSelect)
  }, [accountBalance, sellAssetSelect])

  useEffect(() => {
    if (buyAssetSelect) {
      setBuyAsset(assets?.find(i => Number(i.value) === Number(buyAssetSelect)))
      setData('buy_asset_id', buyAssetSelect)
    }
  }, [buyAssetSelect])

  useEffect(() => {
    setData('use_unlocked_balance', !!isReal)
  }, [isReal])

  useEffect(() => {
    if (assets?.length) {
      setData(data => ({...data,
        buy_asset_id: buyAsset.value ? buyAsset.value : assets[0].value,
        sell_asset_id: assets.find(i => i.label === sellAsset)?.value,
        use_unlocked_balance: !!isReal
      }))
    }
  }, [accountBalance, isReal, assets])

  return (
    <React.Fragment>
      {/*<div className="p-4 bg-light-warning px-0">*/}
      {/*  <div className="float-end ms-2">*/}
      {/*    <h6 className="text-warning mb-0">*/}
      {/*      <span className="text-dark">{isTwoPrecision(sellAsset, assetBalance)} (${toTwoPrecision(assetUsdBalance)})</span>*/}
      {/*    </h6>*/}
      {/*  </div>*/}
      {/*  <h6 className="mb-0 text-danger">{t("Sell asset balance")} : </h6>*/}
      {/*</div>*/}
      <Form onSubmit={submitHandler}>
        <div className="pt-3 pb-5 px-5">
          <Row className="mb-3 mt-5">

            {/* BEGIN Sell asset select */}
            <Col xs={6}>
              <div className="form-floating border border-gray-300 rounded mb-0 pb-0">
                <select
                  className='form-select form-select-transparent bg-body'
                  name="sell_asset_id"
                  aria-hidden="true"
                  onChange={sellSelectHandler}
                  value={sellAssetSelect || assets.find(i => i.code === sellAsset)?.asset_id}
                >
                  {assets?.length
                    ? assets.map((asset) => {
                      if (accountBalance?.find(i => i.code === asset.label)) {
                        return <option key={asset.value} value={asset.value}>{assetFullName[asset.label]}/{asset.label}</option>
                      }
                    })
                    : <option>No options</option>
                  }
                </select>
                <label className="fs-5 fw-bold" htmlFor="floatingInputValue">{t("Sell asset")}</label>
              </div>
            </Col>
            {/* END Sell asset select */}

            {/* BEGIN Buy asset select */}
            <Col xs-={6}>
              <div className="form-floating border border-gray-300 rounded mb-0 pb-0">
                <select
                  className='form-select form-select-transparent bg-body'
                  name="buy_asset_id"
                  aria-hidden="true"
                  onChange={buySelectHandler}
                >
                  {assets?.length
                    ? assets.map((asset) => {
                      return <option key={asset.value} value={asset.value}>{assetFullName[asset.label]}/{asset.label}</option>
                    })
                    : <option>No options</option>
                  }
                </select>
                <label className="fs-5 fw-bold" htmlFor="floatingInputValue">{t("Buy asset")}</label>
              </div>
            </Col>
            {/* END Buy asset select */}

          </Row>
          <div>

            {/* BEGIN Sell amount */}
            <div className="input-group mb-3">
              <label className="input-group-text fw-bold fs-5">{t("Sell amount")}</label>
              <Input
                type="text"
                className="form-control fw-bold fs-5"
                name="sell_amount"
                onInput={sellAmountInputHandler}
                value={data.sell_amount}
                placeholder="0.00000000"
                autoComplete='off'
              />
            </div>
            {/* END Sell amount */}

            {/* BEGIN Buy amount */}
            <div className="input-group mb-3">
              <label className="input-group-text fw-bold fs-5">{t("Buy amount")}</label>
              <Input
                type="text"
                className="form-control fw-bold fs-5"
                name="buy_amount"
                value={data.buy_amount}
                onInput={buyAmountInputHandler}
                placeholder="0.00000000"
                autoComplete='off'
              />
            </div>
            {/* END Buy amount */}

          </div>
          <div className="mt-3 pt-2">
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <div className="fs-13 mb-0">
                  {t("Transaction Fees")}
                  <span className="text-gray-600 ms-1 fs-11">({exchangeFee}%)</span>
                  {!isReal &&
                    <span>
                      <i
                        className="text-gray-600 mb-0 ps-1 fs-13 bi bi-info-circle"
                        data-tooltip-id={'fee'}
                        data-tooltip-content={t('Paying from Available Balance')}
                      ></i>
                    <Tooltip id={'fee'}/>
                    </span>
                  }
                </div>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {sellAsset} {isTwoPrecision(sellAsset, feeAmount)} (${toTwoPrecision(feeAmount * sellAssetUsdPrice)})
                </h6>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <p className="fs-13 mb-0">{t("Total sell amount")}:</p>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {`${sellAsset ? sellAsset : ''} ${sellAmount ? isTwoPrecision(sellAsset, sellAmount) : '0.00'}
                    ($${sellAsset ? (!isReal && sellAsset ? buyAmountInUsd :
                    toTwoPrecision(Number(buyAmountInUsd) + (feeAmount * sellAssetUsdPrice))) : '0.00'})`}
                </h6>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <p className="fs-13 fw-semibold mb-0">
                  {!isReal ? t("Total pay amount:") : t("You receive:")}
                </p>
              </div>
              <div className="flex-shrink-0">
                {!isReal ?
                  <h6 className="mb-0 fw-semibold">
                    {`${sellAsset ? sellAsset : ''} ${buyAmount ? isTwoPrecision(sellAsset, totalPayAmount) : '0.00'}
                     ($${sellAsset ? toTwoPrecision(totalPayAmount * sellAssetUsdPrice) : '0.00'})`}
                  </h6> :
                  <h6 className="mb-0 fw-semibold">
                    {`${buyAsset.label ? buyAsset.label : 'BTC'}
                     ${buyAmount ? buyAmount : '0.00'}
                     ($${buyAmount ? buyAmountInUsd : '0.00'})`}
                  </h6>
                }
              </div>
            </div>
          </div>
          <Row className="mb-3">
            <Col xs={12}>
              <div>
                <a href="#" className="text-primary cursor-pointer py-2 pe-2"
                   onClick={(e) => calculateMaxSumHandler(e)}>{t("Max sum")}</a>
              </div>
            </Col>
          </Row>
          <div className="mt-3 pt-2">
            <button type="submit" className="btn btn-primary w-100">{t("Exchange")}</button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default ExchangeWidgetForm

