import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
// import {ThemeModeComponent} from '../../../assets/ts/layout'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'
import React, {useState} from 'react'
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import {Link} from "@inertiajs/react"


type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

// const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const ThemeModeSwitcher = ({
  // toggleBtnClass = '',
  // toggleBtnIconClass = 'fs-1',
  // menuPlacement = 'bottom-end',
  // menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  // const calculatedMode = mode === 'system' ? systemMode : mode
  const [isDropdown, setIsDropdown] = useState(false)
  const [mouseOnItem, setMouseOnItem] = useState('')

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  // const toggleDropdown = () => {
  //   setIsDropdown(!isDropdown)
  // }

  return (
    <>
      {/* begin::Menu toggle */}
      {/*<a*/}
      {/*  href='#'*/}
      {/*  className={clsx('btn btn-icon ', toggleBtnClass)}*/}
      {/*  data-kt-menu-trigger={menuTrigger}*/}
      {/*  data-kt-menu-attach='parent'*/}
      {/*  data-kt-menu-placement={menuPlacement}*/}
      {/*>*/}
      <Dropdown
        isOpen={isDropdown}
        // toggle={toggleDropdown}
        onMouseEnter={() => setIsDropdown(true)}
        onMouseLeave={() => setIsDropdown(false)}
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon"
        >
          <KTIcon iconName={clsx(mode === 'dark' ? 'moon' : 'night-day')} className="fs-1"/>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg py-0">
          <div className='d-flex align-items-center px-2 py-2 pt-5 w-100'>
            <Link
              href="#"
              onClick={() => {
                switchMode('light')
                // toggleDropdown()
              }}
              className={clsx(
                'w-100 p-1 px-2 rounded-3',
                mouseOnItem === 'light' ? 'text-primary bg-light-primary' : 'text-gray-700'
              )}
              onMouseEnter={() => setMouseOnItem('light')}
              onMouseLeave={() => setMouseOnItem('')}
            >
              <div className="d-flex w-100 align-items-center justify-content-center">
                <KTIcon iconName='night-day' className={clsx('fs-1 me-3', mouseOnItem === 'light' ? 'text-primary' : 'text-gray-800')}/>
                <div className="w-100 text-start fs-5">Light</div>
              </div>
            </Link>
          </div>

        <div className='d-flex align-items-center px-2 py-2 pb-5 w-100'>
          <Link
            href="#"
            onClick={() => {
              switchMode('dark')
              // toggleDropdown()
            }}
            className={clsx(
              'w-100 p-1 px-2 rounded-3',
              mouseOnItem === 'dark' ? 'text-primary bg-light-primary' : 'text-gray-700'
            )}
            onMouseEnter={() => setMouseOnItem('dark')}
            onMouseLeave={() => setMouseOnItem('')}
          >
            <div className="d-flex w-100 align-items-center justify-content-center">
              <KTIcon iconName='moon' className={clsx('fs-1 me-3', mouseOnItem === 'dark' ? 'text-primary' : 'text-gray-800')}/>
              <div className="w-100 text-start fs-5">Dark</div>
            </div>
          </Link>
        </div>

        {/*<div className='menu-item px-3 my-0'>*/}
        {/*  <a*/}
        {/*    href='#'*/}
        {/*    className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}*/}
        {/*    onClick={() => switchMode('system')}*/}
        {/*  >*/}
        {/*<span className='menu-icon' data-kt-element='icon'>*/}
        {/*  <KTIcon iconName='screen' className='fs-1'/>*/}
        {/*</span>*/}
        {/*    <span className='menu-title'>System</span>*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/* end::Menu item */}
        {/*</DropdownItem>*/}

      </DropdownMenu>
    </Dropdown>
</>
)
}

export {ThemeModeSwitcher}
