import React, {useContext, useEffect, useState} from 'react'
import {Button, Col} from "reactstrap"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {clsx} from "clsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import _ from "lodash"
// Components
import BuySecuritiesModal from "@metronic/Components/Securities/Modals/BuySecuritiesModal.jsx"
import {preparePrivateFields, SecurityDetailsTabs} from "@metronic/Components/Securities/Components.jsx"
import SecurityDetailsDropdown from "@metronic/Components/Securities/Blocks/Dropdown/SecurityDetailsDropdown.jsx"
// Tabs
import {
  OverviewTab,
  AboutTab,
  StatsTab,
  DetailsTab
} from "@metronic/Components/Securities/Blocks/SecurityDetailsTabsContents"
// import {getIndicesRates, getStocksRates} from "@metronic/api/securities.js";
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"
import SellSecuritiesModal from "@metronic/Components/Securities/Modals/SellSecuritiesModal.jsx"

const SecurityDetail = (props) => {
  const {
    selectedSecurities,
    resetSelectedSecurities,
    isOwnDetails,
    acquiredSecurity,
    consultEmail,
    selectedSaap,
    updateDetailsTable
  } = props
  const {openModal} = useContext(ModalContext)
  const {ownListTab, setActiveTab, activeTab, setOwnSelectedSecurities, ownSelectedSecurities} = useSecuritiesContext()
  const [securityData, setSecurityData] = useState([])
  // const [securityTicker, setSecurityTicker] = useState('')
  // const [securityTickerData, setSecurityTickerData] = useState('')
  // const [tickerType, setTickerType] = useState('')
  // const [tickerRange, setTickerRange] = useState('month')
  const [activeTableTab, setActiveTableTab] = useState('overview')
  const [isImageUrlValid, setIsImageUrlValid] = useState(true)
  const [timeLeft, setTimeLeft] = useState({})

  const toggleTab = (tab) => {
    if (tab !== activeTableTab) {
      setActiveTableTab(tab)
    }
  }

  const openBuySecuritiesModal = (selectedSecurities) => {
    openModal({
      title: 'Create order',
      content: <BuySecuritiesModal
        setOwnSelectedSecurities={setOwnSelectedSecurities}
        selectedSecurities={selectedSecurities}
        ownListTab={ownListTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        updateDetailsTable={updateDetailsTable}
      />,
      size: 'lg'
    })
  }

  const openSellSecuritiesModal = (acquiredSecurity) => {
    openModal({
      title: 'Sell order',
      content: <SellSecuritiesModal
        acquiredSecurity={acquiredSecurity}
        // ownListTab={ownListTab}
        // setActiveTab={setActiveTab}
        // activeTab={activeTab}
        updateDetailsTable={updateDetailsTable}
      />,
      size: 'lg'
    })
  }

  // const getStocks = (data) => {
  //   if (selectedSecurities.hasOwnProperty('api_data'))  return
  //
  //   getStocksRates(data).then(r => {
  //     setSelectedSecurities((prev) => ({
  //       ...prev,
  //       api_data: {...r}
  //     }))
  //   })
  // }
  //
  // const getIndices = (data) => {
  //   if (selectedSecurities.hasOwnProperty('api_data'))  return
  //
  //   getIndicesRates(data).then(r => {
  //     setSelectedSecurities((prev) => ({
  //       ...prev,
  //       api_data: [...r.original]
  //     }))
  //   })
  // }
  //
  // useEffect(() => {
  //   if (selectedSecurities && selectedSecurities.meta) {
  //     let data = Object.entries(selectedSecurities?.meta)
  //
  //     if (data && data.length) {
  //       let tickerBlock = data.find(i => i[0] === 'ticker')
  //
  //       if (tickerBlock) {
  //         setTickerType(selectedSecurities.type)
  //         setSecurityTicker(tickerBlock[1]?.value)
  //       } else {
  //         setTickerType('')
  //         setSecurityTicker('')
  //       }
  //     }
  //
  //     const filteredData = data.filter(([key, value]) => !value?.hidden_in_details)
  //     setSecurityData(filteredData.map(item => (
  //       {[item[0]]: item[1]}
  //     )))
  //   }
  // }, [selectedSecurities])
  //
  // useEffect(() => {
  //   if (selectedSecurities && selectedSecurities?.name && securityTicker && tickerRange) {
  //
  //     if (tickerType && tickerType === 'stocks') {
  //       getStocks({stock_ticker: securityTicker, range: tickerRange})
  //     } else if (tickerType && tickerType === 'indices') {
  //       getIndices({index_ticker: securityTicker, range: tickerRange})
  //     } else {
  //       setSecurityTickerData(null)
  //     }
  //
  //   }
  //
  // }, [securityTicker, tickerType])
  //
  // useEffect(() => {
  //   if (!securityTicker && !tickerType) {
  //     setSecurityTickerData([])
  //   }
  // }, [securityTicker, tickerType])

  useEffect(() => {
    if (selectedSecurities && selectedSecurities.meta) {
      const filteredData = Object.entries(selectedSecurities.meta).filter(([key, value]) => !value?.hidden_in_details)
      setSecurityData(filteredData.map(item => (
        { [item[0]]: item[1] }
      )))
    }
  }, [selectedSecurities])

  useEffect(() => {
    if (!_.isEmpty(selectedSecurities) && securityData && isOwnDetails && !_.isEmpty(acquiredSecurity)) {
      preparePrivateFields(
        selectedSecurities,
        acquiredSecurity,
        isOwnDetails,
        timeLeft, setTimeLeft,
        setSecurityData
        )
    }
  }, [isOwnDetails, acquiredSecurity, selectedSecurities])

  useEffect(() => {
    if (!_.isEmpty(timeLeft)) {
      setSecurityData((prevState = []) => {
        const timeLeftIndex = prevState.findIndex(item => item.time_left)

        if (timeLeftIndex !== -1) {
          return prevState.map((item, index) =>
            index === timeLeftIndex
              ? {
                ...item,
                time_left: {
                  label: 'Times left',
                  value: <span className='text-muted fs-6'>
                    {timeLeft.years} years, {timeLeft.months} months, {timeLeft.days} days,
                    <span className="ms-1">
                      {timeLeft.hours}:
                      {timeLeft.minutes < 10 ? '0' : ''}{timeLeft.minutes}:
                      {timeLeft.seconds < 10 ? '0' : ''}{timeLeft.seconds}
                    </span>
              </span>
                }
              }
              : item
          )
        }

        return prevState
      })
    }
  }, [timeLeft])

  useEffect(() => {
    if (selectedSecurities?.name && !activeTableTab) {
      setActiveTableTab('overview')
    }

    if (selectedSecurities?.name && activeTableTab === 'stats' && selectedSecurities.type === SecurityType.BOND) {
      setActiveTableTab('overview')
    }

    if (
      selectedSecurities && activeTableTab === 'about' && selectedSecurities.meta &&
      (selectedSecurities.type === SecurityType.BOND || selectedSecurities.type === SecurityType.INDEX) &&
      (!selectedSecurities.meta['about'] || !selectedSecurities.meta['about']['value'])
    ) {
      setActiveTableTab('overview')
    }
  }, [selectedSecurities])

  // useEffect(() => {
  //   if (ownSelectedSecurities.name && detailsRef.current) {
  //     setTimeout(() => {
  //       detailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
  //     }, 300)
  //   }
  // }, [ownSelectedSecurities, detailsRef.current])

  return (
    <>
      {selectedSecurities?.name ?
        <Col className="col-12">
          <div className="d-flex align-items-center mb-4 border-bottom pb-1">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-center me-2 text-truncate">
                <div onClick={() => resetSelectedSecurities()}>
                  <KTIcon iconName="arrow-left" className="fs-3x cursor-pointer me-3 d-block d-sm-none"/>
                </div>
                <div>
                  {(selectedSecurities && selectedSecurities.meta && selectedSecurities.meta['logo'].value && isImageUrlValid) ?
                    <div className="symbol symbol-35px border me-3">
                      <img
                        src={selectedSecurities?.meta['logo'].value}
                        alt="image"
                        onError={() => setIsImageUrlValid(false)}
                      />
                    </div> :
                    <div className="symbol symbol-35px border me-3">
                      <div className="symbol-label fs-2 fw-semibold text-primary">
                        {selectedSecurities?.name.slice(0, 1)}
                      </div>
                    </div>
                  }
                </div>
                <div className="d-flex flex-column ms-1 text-nowrap">
                  <span className="text-gray-700 fs-4 fw-bolder">{selectedSecurities?.name}</span>
                  {/*<span className="fs-8 text-gray-600 fw-bold">*/}
                  {/*{selectedSecurities?.meta ?*/}
                  {/*  `${selectedSecurities.meta['current_price']?.value} ${selectedSecurities.meta['payout_asset']?.payout_asset_code || '$'}` :*/}
                  {/*  '-'*/}
                  {/*  }*/}
                  {/*</span>*/}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <SecurityDetailsDropdown
                  openBuySecuritiesModal={openBuySecuritiesModal}
                  selectedSecurities={selectedSecurities}
                  consultEmail={consultEmail}
                  isOwnDetails={isOwnDetails}
                  acquiredSecurity={acquiredSecurity}
                  openSellSecuritiesModal={openSellSecuritiesModal}
                />
              </div>

            </div>

          </div>

          {/* Tabs list BEGIN */}
          <SecurityDetailsTabs
            toggleTab={toggleTab}
            activeTableTab={activeTableTab}
            isOwnSecurity={isOwnDetails}
            selectedSecurities={selectedSecurities}
          />
          {/* Tabs list END */}

          {/* Tabs content BEGIN */}
          <div className="tab-content">

            {/* Overview Tab content BEGIN */}
            <div className={clsx('tab-pane fade show', activeTableTab === 'overview' && 'active')} role="tabpanel">
              <OverviewTab
                securityData={securityData}
                selectedSecurities={selectedSecurities}
                consultEmail={consultEmail}
                selectedSaap={selectedSaap}
                isOwnSecurity={isOwnDetails}
                activeTableTab={activeTableTab}
              />
            </div>
            {/* Overview Tab content END */}

            {/* Stats Tab content BEGIN */}
            <div className={clsx('tab-pane fade show', activeTableTab === 'stats' && 'active')} role="tabpanel">
              <StatsTab
                securityData={securityData}
                // securityTicker={securityTicker}
                // tickerType={tickerType}
                selectedSecurities={selectedSecurities}
                // setRangeHandler={setRangeHandler}
                // tickerRange={tickerRange}
                // securityTickerData={securityTickerData}
                consultEmail={consultEmail}
              />
            </div>
            {/* Stats Tab content BEGIN */}

            {/* About Tab content BEGIN */}
            <div className={clsx('tab-pane fade show', activeTableTab === 'about' && 'active')} role="tabpanel">
              <AboutTab
                activeTableTab={activeTableTab}
                selectedSecurities={selectedSecurities}
                consultEmail={consultEmail}
              />
            </div>
            {/* About Tab content BEGIN */}

            {/* Details Tab content BEGIN */}
            <div className={clsx('tab-pane fade show', activeTableTab === 'details' && 'active')} role="tabpanel">
              <DetailsTab consultEmail={consultEmail} selectedSecurities={acquiredSecurity}/>
            </div>
            {/* Details Tab content BEGIN */}

            {/* Buy button BEGIN */}
            <div className="w-100 d-flex justify-content-center mt-5">
              <Button
                type="button"
                color="success"
                className={`btn btn-sm btn-primary px-10 fw-bold rounded-4 ${isOwnDetails ? 'me-2 w-100 w-lg-25' : 'w-50'}`}
                onClick={() => openBuySecuritiesModal(selectedSecurities)}
              >
                Buy
              </Button>
              {isOwnDetails ?
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-sm btn-primary px-10 fw-bold rounded-4 ms-2 w-100 w-lg-25"
                  onClick={() => openSellSecuritiesModal(acquiredSecurity)}
                >
                  Sell
                </Button> : null
              }
            </div>
            {/* Buy button BEGIN */}

          </div>
          {/* Tabs content END */}

        </Col> : null
      }
    </>
  )
}

export default SecurityDetail
