import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {Col, Input, Label, Row} from "reactstrap"
import {orderSecurity} from "@metronic/api/securities.js"
import {toast} from "react-toastify"
import {formatSecuritiesPrice, isTwoPrecision, reloadData} from "@metronic/helpers/utils.jsx"
import {selectListData} from "@metronic/api/general.js"

const BuySecuritiesModal = (props) => {
  const {
    selectedSecurities,
    ownListTab,
    activeTab,
    setActiveTab,
    setOwnSelectedSecurities,
    updateDetailsTable
  } = props
  const {closeModal} = useContext(ModalContext)
  const [quantity, setQuantity] = useState('')
  const [limit, setLimit] = useState('')
  const [payoutAssetId, setPayoutAssetId] = useState('')
  const [payoutAsset, setPayoutAsset] = useState('')
  const [securityPrice, setSecurityPrice] = useState(0)

  const makeSecuritiesOrder = () => {
    orderSecurity({security_id: selectedSecurities?.id, quantity: quantity}).then(r => {
      if (r.success) {
        toast.success(r.message)

        if (ownListTab && (ownListTab !== activeTab)) {
          setActiveTab(ownListTab)
        }

        setOwnSelectedSecurities(selectedSecurities)
        reloadData(['customer_securities', 'account_balance'])
        closeModal()
        updateDetailsTable()
      } else {
        toast.error(r.message)
      }
    })
      .catch(r => {
        toast.error(r)
      })
  }

  const getPayoutAsset = (id) => {
    selectListData('assets_list').then(r => {
      setPayoutAsset(r.find(i => i.value === Number(id)))
    })
  }

  useEffect(() => {
    if (selectedSecurities) {
      setLimit(selectedSecurities.meta?.limit?.value)
      setPayoutAssetId(selectedSecurities.meta?.payout_asset?.value)
    }
  }, [selectedSecurities])

  useEffect(() => {
    if (payoutAssetId) {
      getPayoutAsset(payoutAssetId)
    }
  }, [payoutAssetId])

  useEffect(() => {
    if (payoutAsset && selectedSecurities) {
      setSecurityPrice(Number(selectedSecurities.meta?.current_price?.value))
    }
  }, [payoutAsset, selectedSecurities])

  return (
    <>
      <Row className="border-top pt-5">
        <Col>
          <h1 className="text-center">{selectedSecurities?.name}</h1>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="bg-light-primary w-100 text-center h-auto rounded-2 d-flex align-items-center justify-content-center">
            <span className="fs-6 fw-bold text-gray-600 p-5">
              You have selected the security <span className="text-primary">"{selectedSecurities?.name}"</span>.
              Enter the quantity you wish to
              purchase and make sure you have sufficient funds in your account to complete the transaction.
              Once confirmed, the corresponding amount will be deducted from your balance, and the purchased
              securities will be added to your portfolio.
               {/*Our agent will contact you shortly with further instructions.*/}
            </span>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="col-6">
          <label className="mb-0 form-label fw-bold text-center w-100">Limit</label>
          <input
            type="text"
            className="form-control form-control-solid fw-bold text-center"
            placeholder="Limit"
            disabled
            value={limit || 'No data'}
            onChange={() => {}}
          />
        </Col>
        <Col className="col-6">
          <label className="mb-0 form-label fw-bold text-center w-100">Payout asset</label>
          <input
            type="text"
            className="form-control form-control-solid fw-bold text-center"
            placeholder="Payout asset"
            disabled
            value={payoutAsset ? `${payoutAsset?.name}/${payoutAsset?.label}` : 'United States Dollar/USD'}
            onChange={() => {}}
          />
        </Col>
      </Row>

      <Row className="mt-5 pb-5 border-bottom-dashed border-1 border-gray-500">
        <Col className="col-12 d-flex flex-column">
          <div className="d-flex align-items-end">
            <div className="w-100">
              <Label className="mb-0 fw-bold">Quantity</Label>
              <Input
                type="text"
                className="form-control fw-bold text-gray-800 w-100 fs-4"
                autoComplete="off"
                placeholder="Enter the quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="ms-3">
              <button
                type="button"
                className="btn btn-light-success fs-3 fw-bold ms-2 text-nowrap"
                onClick={() => makeSecuritiesOrder()}
              >
                Create order
              </button>
            </div>
          </div>

          <div className="text-center w-100 py-3 fw-bold fs-4">
            Price: <span className="fw-bold text-primary">
            {formatSecuritiesPrice(payoutAsset?.label, securityPrice.toFixed(2) || '0.00')}
          </span>,
            Total Payment: <span className="fw-bold text-primary">
            {isTwoPrecision(payoutAsset?.label, (securityPrice * Number(quantity)))}
            {payoutAsset?.label ? <span className="text-gray-600 ms-2 me-2">{payoutAsset?.label}</span> : 'USD'}
            ({formatSecuritiesPrice('USD', ((securityPrice * Number(quantity)) * payoutAsset?.price).toFixed(2) || '0.00')})
          </span>
          </div>
        </Col>
      </Row>

      <Row className="mt-6">
        <Col>
          <div className="d-flex justify-content-start">
            <button
              type="button"
              className="btn btn-light-warning fs-3 py-2 fw-bold me-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default BuySecuritiesModal
