import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import {usePage} from "@inertiajs/react"
import _ from "lodash"
import {MenuItem} from "../../header/MenuItem";
import {MenuInnerWithSub} from "../../header/MenuInnerWithSub"

export function AsideMenuMainPlanner() {
  // const intl = useIntl()
  const {props: pageProps} = usePage()
  const [securitiesData, setSecuritiesData] = useState([])
  const [currentPage, setCurrentPage] = useState('')
  const pathName = window.location.pathname
  const showTrade = import.meta.env.VITE_SHOW_TRADE
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES

  useEffect(() => {
    if (pageProps.securities_pages && pageProps.securities_pages?.length) {
      setSecuritiesData(pageProps.securities_pages?.filter(i => i.visible))
    }
  }, [pageProps])

  useEffect(() => {
    if (pathName) {
      let clearName = pathName.split('/', 2)
      setCurrentPage(clearName ? _.capitalize(clearName[1]) : '')
    }
  }, [pageProps])

  return (
    <>
      <MenuItem asidePlanner={true} icon='bank' title="Dashboard" to='dashboard' currentPage={currentPage} />
      <MenuItem asidePlanner={true} icon='arrow-right-left' title='Exchange' to='exchange' currentPage={currentPage}/>

      <MenuInnerWithSub
        title='Deposit'
        currentPage={currentPage}
        to='#'
        icon='entrance-right'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
        asidePlanner={true}
      >
        <MenuItem asidePlanner={true} to='deposit.credit-card' title='Credit Card' hasBullet={true} />
        <MenuItem asidePlanner={true} to='deposit.crypto' title='Crypto' hasBullet={true} />
        <MenuItem asidePlanner={true} to='deposit.bank-transfer' title='Bank Transfer' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Withdrawal'
        to='#'
        currentPage={currentPage}
        icon='exit-up'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
        asidePlanner={true}
      >
        <MenuItem asidePlanner={true} to='withdrawal.crypto' title='Crypto' hasBullet={true} />
        <MenuItem asidePlanner={true} to='withdrawal.bank-transfer' title='Bank Transfer' hasBullet={true} />
      </MenuInnerWithSub>

      {showTrade === 'true' ?
        <MenuInnerWithSub
          title='Trade'
          to='#'
          icon='update-file'
          hasArrow={true}
          currentPage={currentPage}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          asidePlanner={true}
        >
          <MenuItem asidePlanner={true} to='p2p-orders.p2pOrders' title='Peer-to-peer' hasBullet={true} />
        </MenuInnerWithSub> : null
      }

      <MenuItem asidePlanner={true} icon='dollar' to='transactions' title="Transactions" currentPage={currentPage}/>
      <MenuItem asidePlanner={true} icon='tag' to='invoices.index' title='Invoices' currentPage={currentPage}/>
      <MenuItem asidePlanner={true} icon='message-question' to='support' title='Support' currentPage={currentPage}/>

      {(showSecurities === 'true' && securitiesData?.length) ?
        <MenuInnerWithSub
          title='Securities'
          to='#'
          icon='shield'
          hasArrow={true}
          currentPage={currentPage}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          asidePlanner={true}
        >
          {(securitiesData && securitiesData?.length)  ?
            securitiesData.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  to='securities.getPage'
                  title={item.name}
                  slug={item.slug}
                  hasBullet={true}
                  asidePlanner={true}
                />
              )
            }) : null
          }
        </MenuInnerWithSub> : null
      }
    </>
  )
}
