import React, {useEffect, useRef, useState} from 'react'
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {clsx} from "clsx"
import {prettyDate, reloadData} from "@metronic/helpers/utils.jsx"
import ChatBodyScrollBar from "@metronic/Components/Support/ChatBodyScrollBar.jsx"
import {router, useForm, usePage} from "@inertiajs/react"
import {Button, Input} from "reactstrap"
import {createChatTicket} from "@metronic/api/support.js"

const Chat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isLayout, setIsLayout] = useState(false)
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [initialMessage, setInitialMessage] = useState({})
  const [isScrollTopButton, setIsScrollTopButton] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const chatTicket = usePage().props.chat_ticket

  const {setData, data, post, reset} = useForm({
    subject: '',
    message: '',
    comment: '',
    chat: '',
  })

  const toggleChat = (e) => {
    e.preventDefault()

    setIsChatOpen(!isChatOpen)
  }

  const sendMessage = () => {
    setIsLayout(true)

    post(`/support/comment/${ticketId}`, {
      preserveScroll: true,
      onSuccess() {
        reloadData(['chat_ticket'])
        reset()
        setIsLayout(false)
      },
      onError() {
        setIsLayout(false)
      }
    })
  }

  const createChat = () => {
    setIsLayout(true)

    createChatTicket(data).then(r => {
      if (r.success) {
        reloadData(['chat_ticket'])
        setIsLayout(false)
        reset()
      }
    })
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      createChat()
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrollTopButton(true)
      } else {
        setIsScrollTopButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    if (chatTicket) {
      setInitialMessage((Number(chatTicket.length) !== 0) ? {
        created_at: chatTicket.created_at,
        deleted_at: chatTicket.deleted_at,
        message: chatTicket.message,
        user_id: chatTicket.customer_id,
        status: chatTicket.status,
        last_update: chatTicket.updated_at
      } : false)

      setTicketId(String(chatTicket.id))
    }
  }, [chatTicket])

  useEffect(() => {
    if (initialMessage && chatTicket && chatTicket.comments?.length) {
      setMessages([initialMessage, ...chatTicket.comments])
    } else if (chatTicket) {
      setMessages([initialMessage])
    } else {
      setMessages([])
    }
  }, [chatTicket, initialMessage])

  return (
    <>

      {/* BEGIN Chat body */}
      <div
        className={clsx('w-sm-450px bg-body card position-fixed shadow rounded-4 chat-body chat-body-position',
          isChatOpen ? 'd-block' : 'd-none',
          isScrollTopButton && 'ci-top'
        )}
      >
        {chatTicket &&
          <div className="card-header d-flex align-items-center justify-content-center">
            <div
              className='card-title text-gray-700 me-12 text-center fs-5 fw-bold d-flex flex-column
                justify-content-center align-items-center'
            >
              {chatTicket?.subject}
              <span className="fs-7 text-gray-600">{prettyDate(chatTicket?.created_at)}</span>
            </div>
          </div>
        }

        {/* BEGIN Chat scrollbar */}
        <div className="p-5 scroll-y card-body h-400px position-relative">
          {chatTicket ?
            <ChatBodyScrollBar data={messages} initialMessage={initialMessage} isChatOpen={isChatOpen}/>
            :
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="fs-1 text-gray-700 text-center">
                You do not have an open chat. But you can open one.
              </div>
            </div>
          }
        </div>
        {/* END Chat scrollbar */}

        {/* BEGIN Card footer */}
        <div className={clsx('card-footer px-5 pt-4 d-flex', !chatTicket && 'flex-column')}
             id='kt_chat_messenger_footer'>
          <Input
            type="text"
            placeholder="Enter subject"
            className={clsx('mb-3', chatTicket ? 'd-none' : 'd-block')}
            value={data.subject}
            onChange={(e) => setData('subject', e.target.value)}
          />
          <textarea
            className={clsx('form-control', chatTicket ? ' me-3' : 'mb-3')}
            rows={2}
            data-kt-element='input'
            placeholder='Type a message'
            value={data.message}
            onChange={(e) => {
              setMessage(e.target.value)
              setData(data => ({
                ...data,
                comment: e.target.value,
                message: e.target.value,
                chat: true,
              }))
            }}
            onKeyDown={onEnterPress}
          ></textarea>

          <div className={clsx('d-flex align-items-center', !chatTicket ? 'justify-content-center' : '')}>
            <button
              className={clsx('btn btn-sm btn-primary px-5 fw-bold',
                chatTicket ? '' : 'w-50'
              )}
              type='button'
              data-kt-element='send'
              onClick={() => {
                if (chatTicket) {
                  sendMessage()
                } else {
                  createChat()
                }
              }}
            >
              Send
            </button>
          </div>
        </div>
        {/* END Card footer */}

        {/* BEGIN Preloader */}
        <div className={clsx("bg-body w-100 h-100 opacity-50 position-absolute top-0 left-0",
          isLayout ? 'd-flex align-items-center justify-content-center' : 'd-none'
        )}
        >
          <div className="chat-loader"></div>
        </div>
        {/* END Preloader */}

        {/* BEGIN Close button */}
        <div
          className="position-absolute cursor-pointer"
          style={{top: '8px', right: '5px'}}
          onClick={(e) => toggleChat(e)}
        >
          <i className='bi bi-x fs-2x'></i>
        </div>
        {/* END Close button */}

        {/* BEGIN Refresh button */}
        <Button
          color="text-muted"
          outline
          style={{top: '0px', right: '30px'}}
          className="hstack justify-content-center text-muted align-items-center px-1 position-absolute"
          onClick={() => reloadData(['chat_ticket'])}
        >
          <i className="bi bi-bootstrap-reboot fs-2 refresh"></i>
        </Button>
        {/* END Refresh button */}

      </div>
      {/* END Chat body */}

      {/* BEGIN Chat button */}
      <a
        href="#"
        className={clsx('chat-icon bg-primary d-print-none', isChatOpen ? 'opacity-100' : '', isScrollTopButton && 'ci-top')}
        onClick={(e) => toggleChat(e)}
      >
        <KTIcon iconName='messages' className='fs-2x cursor-pointer text-white'/>
      </a>
      {/* BEGIN Chat button */}

    </>
  )
}

export default Chat
